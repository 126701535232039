import { Vue, Component } from 'vue-property-decorator';
import { IBlog, NAMESPACE as BLOG_NAMESPACE, PixcapArticle } from '@/models/store/blog.interface';
import { mapState } from 'vuex';
import { DEFAULT_BLOG_LOCALE } from '@/constants/blog.constants';

@Component({
	name: 'BlogMixin',
	computed: {
		...mapState(BLOG_NAMESPACE, {
			locale: (state: IBlog) => state.locale,
		}),
	},
})
export default class ArticleMixin extends Vue {
	article: PixcapArticle = null;
	locale: IBlog['locale'];

	get categoryColor() {
		if (!this.article.category) return 'var(--pixcap-neutral-500)';

		switch (this.article.category.name) {
			case 'Design':
				return 'rgb(220, 50, 137)';
			case 'Tutorials':
				return 'rgb(100, 78, 229)';
			case 'Inspiration':
				return 'rgb(2, 146, 132)';
			case 'Announcements':
				return 'rgb(58, 151, 228)';
			case 'Social Media':
				return 'rgb(215, 133, 26)';
			case 'Business':
				return 'rgb(26, 85, 214)';
			default:
				return 'var(--pixcap-neutral-500)';
		}
	}

	get title() {
		return this.article?.title;
	}

	get thumbnailAltText() {
		return this.article?.thumbnailAltText;
	}

	get content() {
		return this.article?.content;
	}

	get slug() {
		return this.article?.slug;
	}

	get publishedAt() {
		return this.article?.publishedAt?.toLocaleDateString(this.locale || DEFAULT_BLOG_LOCALE, {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}

	get categoryName() {
		return this.article?.category?.name;
	}

	get description() {
		return this.article?.description;
	}

	get thumbnailUrl() {
		return this.article?.thumbnailUrl;
	}

	get largeThumbnailUrl() {
		return this.article?.largeThumbnailUrl ?? this.article?.thumbnailUrl;
	}

	get articleDetailUrl() {
		return this.$router.resolve({
			name: 'Article',
			params: {
				articleSlug: this.article.slug,
				locale: this.locale,
			},
		}).href;
	}

	get blogUrl() {
		return this.$router.resolve({ name: 'Blog', params: { locale: this.locale !== DEFAULT_BLOG_LOCALE ? this.locale : null } }).href;
	}
}

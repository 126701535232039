export function ObjectIsEquals(object1: Object, object2: Object): boolean {
	if (!object1 || !object2) return false;
	if (object1 === object2) return true;
	if (Object.keys(object1).length !== Object.keys(object2).length) return false;
	for (const key in object1) {
		if (object2[key] != object1[key]) return false;
	}
	return true;
}

export function ArrayIsEquals(array1: any[], array2: any[]): boolean {
	if (!array1 || !array2) return false;
	if (array1 === array2) return true;
	if (array1.length != array2.length) return false;
	const arraysLength = array1.length;
	for (let i = 0; i < arraysLength; i++) {
		const array1Item = array1[i];
		const array2Item = array2[i];
		if (array1Item instanceof Object && array2Item instanceof Object) {
			if (!ObjectIsEquals(array1Item, array2Item)) return false;
		} else if (array1Item != array2Item) return false;
	}
	return true;
}

export function createArray(arraySize: number, initValue = 0) {
	const arr = [];
	for (let index = 0; index < arraySize; index++) {
		arr.push(initValue);
	}
	return arr;
}

export function isEmptyObject(obj: any) {
	return Object.keys(obj).length == 0;
}

import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
import { REFERRAL_CODE_KEY } from '@pixcap/ui-core/constants/auth.constants';
import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
import { isAuthenticated } from '@/router/utils';
import { BANNER_MODEL_SLUG_ID, MATERIAL_LIBRARY_BANNER_MODEL_SLUG_ID } from '@/constants/pixcapassets.constants';

export function beforeRouteEnter(to, from, next) {
	next(async (vm) => {
		const openAuthenticationModal = to.query.showAuthenticationModal as any;
		const referralCode = to.query.referral as any;
		const openResetPasswordModal = to.query.showResetPasswordModal as any;
		const openUpgradeModal = to.query.showUpgradeModal as any;
		const warnMessage = to.query.warn as any;
		const authRedirect = to.query.authRedirect as any;

		const authRedirectCallback = () => {
			if (authRedirect) {
				if (authRedirect == 'mockup_list') {
					vm.$router.push({ name: 'MockupPage' });
				} else if (authRedirect == 'ai_stylist_mixmatch') {
					vm.$pixcap.$mixpanel.track('Create Project via AI Stylist Homepage CTA');
					vm.$router.push({ name: 'DesignCreateMiddleware', query: { slug: BANNER_MODEL_SLUG_ID } });
				} else if (authRedirect == 'material_library_mixmatch') {
					vm.$pixcap.$mixpanel.track('Create Project via Material Library Homepage CTA');
					vm.$router.push({ name: 'DesignCreateMiddleware', query: { slug: MATERIAL_LIBRARY_BANNER_MODEL_SLUG_ID } });
				} else {
					const path = authRedirect.startsWith('/') ? authRedirect : '/' + authRedirect;
					vm.$router.push({ path });
				}
			}
		};

		if (process.client) {
			if (isAuthenticated(vm.$store)) {
				if (referralCode) {
					vm.$router.replace({ query: null });
					vm.$pixcap.$services.localStorageManager.remove(REFERRAL_CODE_KEY);
					setTimeout(() => {
						vm.$notify({ type: NotificationType.INFO, title: 'You already have a Pixcap account. The link you used is only valid for new users.' });
					}, 1000);
				}
				if (authRedirect) authRedirectCallback();
				if (openUpgradeModal) {
					UserMutations.setUpgradeModalState(vm.$store, {});
				}
			} else {
				if (openAuthenticationModal == 'true' && authRedirect) {
					const redirect = () => {
						vm.$pixcap.$services.authService.setOnAuthenticated(undefined);
						authRedirectCallback();
					};
					vm.$pixcap.$services.authService.setOnAuthenticated(redirect);
				}
				if (referralCode) {
					vm.$pixcap.$mixpanel.track('Open sign up modal with referral code');
					vm.$pixcap.$services.localStorageManager.write(REFERRAL_CODE_KEY, referralCode);
					UserMutations.showAuthenticationModal(vm.$store, true);
				}
				if (openAuthenticationModal === 'true') {
					await AuthActions.logout(vm.$store);
					UserMutations.showAuthenticationModal(vm.$store, true);
				}
				if (openResetPasswordModal === 'true') {
					UserMutations.showResetPasswordModal(vm.$store, true);
				}
			}
		}
		next();
	});
}

export function ensureAuthentication(to, from, next) {
	next((vm) => {
		if (process.client) {
			if (isAuthenticated(vm.$store)) {
				next();
			} else {
				vm.$router.push({ name: 'LibraryExploreList' });
			}
		}
	});
}

export function addQueryParam(key, value, replaceState = false) {
	const url = new URL(window.location.href);
	url.searchParams.set(key, value);
	replaceState ? window.history.replaceState({}, '', url.toString()) : window.history.pushState({}, '', url.toString());
}

// to remove the specific key
export function removeUrlParameter(paramKey, replaceState = false) {
	const url = window.location.href;
	const r = new URL(url);
	r.searchParams.delete(paramKey);
	const newUrl = r.href;
	replaceState ? window.history.replaceState({ path: newUrl }, '', newUrl) : window.history.pushState({ path: newUrl }, '', newUrl);
}

import { SEO_BASE_IMAGE } from '@/constants/seo.constants';

export function setupBaseMetaTags(
	payload: { title?: string; description?: string; image?: string; url?: string; script?: object; noindex?: boolean },
	headMeta,
	route
) {
	if (headMeta.link == null) headMeta.link = [];
	const { title, description, image, url, script, noindex } = payload;
	const _noindex = noindex || (route.query && route.query.noindex);
	if (_noindex) {
		headMeta.meta.push({
			hid: 'robots',
			name: 'robots',
			content: 'noindex, nofollow',
		});
	}

	if (_noindex == null || !_noindex) {
		headMeta.link.push({
			hid: 'canonical',
			rel: 'canonical',
			href: `https://pixcap.com${route.fullPath}`,
		});
	}

	if (title) {
		headMeta.title = title;

		headMeta.meta.push({
			hid: 'og:title',
			property: 'og:title',
			content: title,
		});

		headMeta.meta.push({
			hid: 'twitter:title',
			name: 'twitter:title',
			content: title,
		});
	}
	if (description) {
		headMeta.meta.push({
			hid: 'description',
			name: 'description',
			content: description,
		});
		headMeta.meta.push({
			hid: 'og:description',
			property: 'og:description',
			content: description,
		});
		headMeta.meta.push({
			hid: 'twitter:description',
			name: 'twitter:description',
			content: description,
		});
	}

	headMeta.meta.push({
		hid: 'image',
		name: 'image',
		content: image ?? SEO_BASE_IMAGE,
	});
	headMeta.meta.push({
		hid: 'og:image',
		property: 'og:image',
		content: image ?? SEO_BASE_IMAGE,
	});
	headMeta.meta.push({
		hid: 'twitter:image',
		name: 'twitter:image',
		content: image ?? SEO_BASE_IMAGE,
	});

	if (url) {
		headMeta.meta.push({
			hid: 'og:url',
			property: 'og:url',
			content: url,
		});
	}
	if (script) {
		headMeta.script.push(script);
	}
}

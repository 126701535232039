export function capitaliseString(str: string) {
	if (str == null || str == '') return '';
	const lowerCased = str.toLowerCase();
	return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
}

export function CapitaliseAllFirstLetters(text: string) {
	if (text == null) return null;
	const trimmed = text.trim();
	if (trimmed == '') return null;

	const lowerCased = trimmed.toLowerCase();
	const singleSpaced = lowerCased.replace(/\s\s+/g, ' ');

	const words = singleSpaced.replace('_', ' ').split(' ');
	return words
		.map((word) => {
			return word[0].toUpperCase() + word.substring(1);
		})
		.join(' ');
}

export function highlightTextDirective(el, binding, highlightClassName = 'highlight-search-suggestion') {
	const { value: { suggestion, search } = { search: null, suggestion: null } } = binding;
	if (search) {
		const iQuery = new RegExp(regExpQuote(search), 'ig');
		const innerHTML = suggestion.toString().replace(iQuery, function (matchedTxt) {
			return `<span class="${highlightClassName}">${matchedTxt}</span>`;
		});
		el.innerHTML = innerHTML;
	} else {
		el.innerHTML = suggestion;
	}
}

export function regExpQuote(str) {
	return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
}

export function transformSearchWord(search: string, reverse = false) {
	if (reverse) return search.replaceAll('-', ' ');
	return search.replaceAll(' ', '-');
}

export function transformResourceType(resourceType: string, reverse = false) {
	if (!resourceType) return '';
	resourceType = resourceType.toLowerCase();
	const lastChar = resourceType.length - 1;
	if (reverse) {
		if (resourceType[lastChar] != 's') return resourceType + 's';
	} else if (resourceType[lastChar] == 's') return resourceType.slice(0, -1);
	return resourceType;
}
